<template>
  <div class="elem-flex">
    <setting-attr v-model="userData.name.firstName" :label="'First Name'" />
    <setting-attr v-model="userData.name.lastName" :label="'Last Name'" :darker="true"  />
    <setting-attr v-model="userData.profilePicture" :label="'Profile Picture'"
      :type="'proPic'" :darker="true"
    />

  </div>

</template>

<script>
import ImageSelect from '@/modules/mixins/selectMedia';
import settingAttr from '@/components/UniversalComponents/Settings/settingAttr.vue';

export default {
  name: 'ProfileSettings',
  data() {
    const data = JSON.parse(JSON.stringify(this.$store.getters['user/userData']));
    return {
      userData: data,
    };
  },
  mixins: [ImageSelect],
  components: {
    settingAttr,
  },
  computed: {
    userDataStore() {
      return this.$store.getters['user/userData'];
    },
  },

  methods: {

    changePic() {
      this.attachImage((img) => {
        this.userData.profilePicture = img.data;
        this.edited = true;
      });
    },

  },
  watch: {
    userData: {
      deep: true,
      handler(newVal) {
        const userData = this.$store.getters['user/userData'];
        const editBody = {};
        Object.keys(newVal).forEach((key) => {
          if (newVal[key] !== userData[key]) {
            editBody[key] = newVal[key];
          }
        });

        if (Object.keys(editBody).length > 0) {
          this.$store.dispatch('user/updateUserInfo', this.userData);
        }
      },
    },
    userDataStore: {
      deep: true,
      handler() {
        if (!this.userData.id) {
          this.userData = JSON.parse(JSON.stringify(this.$store.getters['user/userData']));
        }
      },
    },
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

#listGeneralSettings{
  position: relative;
  overflow: hidden;
}

.editing{
  .edit{
    display: none;
  }
}

.elem-setting{
  &.cont-btn{
    justify-content: flex-end !important;
    .btn-green{
      margin-bottom: 0 !important;
      margin-right: 1vh;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
</style>
